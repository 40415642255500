export const TopNav = (props: {banner: Menu}) => {
    const first = props.banner.elements.length > 0 ? props.banner.elements[0] : null

    return (<>
        <div className="hidden lg:block bg-navy-800">
            <div className="max-w-[70rem] mx-auto px-4 xl:px-0 py-3 text-neutral-50 flex items-center space-x-5 leading-[16px] text-[12px]">
                {first ? <>
                    <a
                        className="uppercase bg-teal-100 text-teal-800 inline-block pl-2 pr-2 rounded-full leading-[20px] text-[14px]"
                        href={first.url}
                        title={first.title}
                        target={first.targetBlank ? '_blank' : undefined}
                    >
                        {first.title}
                    </a>
                    <a
                        className="hover:underline flex-grow"
                        href={first.url}
                        title={first.title}
                        target={first.targetBlank ? '_blank' : undefined}
                    >
                        {first.description}
                    </a>
                </> : null}

                {props.banner.elements.slice(1).map(item => <a
                    key={item.title}
                    className={`leading-[20px] text-[14px] hover:underline uppercase ${item.id ? item.id : ''}`}
                    href={item.url}
                    title={item.title}
                    target={item.targetBlank ? '_blank' : undefined}
                >
                    {item.title}
                </a>)}
            </div>
        </div>
        </>
    )
}
