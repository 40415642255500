'use client'

import {Popover} from '@headlessui/react'
import {Logo} from "@/app/layout/header/Logo";
import {CtaSection} from "@/app/layout/header/CtaSection";
import {TopNav} from "@/app/layout/header/TopNav";
import {Menu} from "@/app/layout/header/Menu";
import {MobileMenuOpener} from "@/app/layout/header/MobileMenuOpener";
import {MobileMenu} from "@/app/layout/header/MobileMenu";
import InkeepSearchBar from "@/app/layout/search/InkeepSearchBar";

type DefaultProps = {
    banner: Menu
    menu: Menu
    logoUrl?: string
}

export const Default = ({banner, menu, logoUrl}: DefaultProps) => {
    return <Popover className="header bg-neutral-50 fixed top-0 left-0 right-0 z-50 border-b border-neutral-200 shadow-lg">
        <TopNav banner={banner}/>

        <div className="max-w-[70rem] mx-auto px-4 xl:px-0">
            <div className="flex justify-between items-center py-3 lg:py-0 md:justify-start md:space-x-4">
                <div className="flex justify-start items-center lg:pr-4" style={{height: 42}}>
                    <Logo url={logoUrl}/>
                </div>
                <Menu menu={menu}/>
                <div className={'md:hide flex-grow'}/>
                <InkeepSearchBar />
                <CtaSection/>
                <MobileMenuOpener/>
            </div>
        </div>

        <MobileMenu menu={menu}/>

    </Popover>
}