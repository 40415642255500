export const CtaSection = () => {
    return (
        <div className="hidden md:flex items-center justify-end">
            <a
                href="/company/contact-sales/"
                className="whitespace-nowrap inline-flex items-center justify-center px-3 py-2 border rounded-md shadow-sm text-base font-medium border-cherry bg-cherry text-neutral-50 hover:bg-red-800 hover:border-red-800"
                data-tracking-id={'header:contact-sales'}
            >
                Contact Sales
            </a>
            <a
                href="https://admin.pubnub.com/#/register"
                className="ml-2 whitespace-nowrap inline-flex items-center justify-center px-3 py-2 shadow-sm text-base font-medium text-navy border border-navy-300 bg-neutral-50 hover:bg-navy-300 rounded-md hover:border-navy"
                data-tracking-id={'header:try-for-free'}
            >
                Try for free
            </a>
        </div>
    )
}
