import slugify from 'slugify'
import {MenuGroup} from './MenuGroup'

type MenuElementProps = {
  element: MenuElement
}

export const MenuElement = ({element}: MenuElementProps) => {
  return (element?.subElements && element.subElements.length > 0
      ? <div className={`menu-element menu-${slugify(element.title.toLowerCase())}`}>
        {element.url === '<nolink>'
          ? <span>{element.title}</span>
          : <a href={element.url} data-tracking-id={element.trackingId || null} target={element.targetBlank ? '_blank' : undefined}>{element.title}</a>
        }
        <div className={'dropdown'}>
          {element.subElements.map(group => (<MenuGroup group={group} key={group.title}/>))}
        </div>
      </div>
      : <a href={element.url} data-tracking-id={element.trackingId || null} className={'menu-link'} target={element.targetBlank ? '_blank' : undefined}>
        {element.title}
      </a>
  )
}
