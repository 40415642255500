"use client"

import React from "react";
import dynamic from 'next/dynamic';
import useInkeepSettings from "../../../lib/hooks/useInkeepSettings"

const SearchBar = dynamic(
    () => import('@inkeep/widgets').then((mod) => mod.InkeepSearchBar),
    {
        ssr: false,
    },
);

const ChatButton = dynamic(
    () => import('@inkeep/widgets').then((mod) => mod.InkeepChatButton),
    {
        ssr: false,
    },
);

function InkeepSearchBar() {
    const {
        baseSettings, aiChatSettings, searchSettings, modalSettings,
    } = useInkeepSettings();

    const searchBarProps = {
        stylesheetUrls: ['/styles/searchBar.css'],
        baseSettings,
        aiChatSettings,
        searchSettings,
        modalSettings,
    };

    return <>
        <ChatButton {...searchBarProps} />
        <div className={'w-[20px]'}>
            <SearchBar {...searchBarProps} />
        </div>
    </>
}

export default InkeepSearchBar;
