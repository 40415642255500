import slugify from 'slugify'

type MenuGroupProps = {
    group: MenuElement
}

export const MenuGroup = ({ group }: MenuGroupProps) => (
    <div className={`group group-${slugify(group.title.toLowerCase())}`}>
        {group?.subElements && group?.subElements.map(item => {
            const rel = item.attributes ? item.attributes.replace(/.*rel="([^"]*)".*/, "$1") : undefined
            const target = item.attributes ? item.attributes.replace(/.*target="([^"]*).*"/, "$1") : undefined

            return <a
                key={item.title}
                href={item.url}
                rel={rel}
                target={target}
                data-tracking-id={item.trackingId || null}
            >
                <div className={'link-description'}>
                    <p className={'title'}>{item.title}</p>
                </div>
            </a>
        })}
    </div>
)
