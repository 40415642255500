export const env = {
    domain: () => {
        switch (process.env.APP_ENV) {
            case 'local': return 'http://localhost:3000'
            // TODO: this line might be useful when we setup a staging environment
            // case 'preview': return 'https://pubnub-web-builder.netlify.app'
        }

        return 'https://www.pubnub.com'
    }
};