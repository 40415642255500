import {Fragment} from "react";
import {Transition, Popover} from "@headlessui/react";
import {Logo} from "./Logo";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {MobileMenuElements} from "./MobileMenuElements";
import {env} from "@/lib/env";


type MobileMenuProps = {
  menu: Menu
}

export const MobileMenu = ({menu}: MobileMenuProps) => (
  <Transition
    as={Fragment}
    enter="duration-200 ease-out"
    enterFrom="opacity-0 scale-95"
    enterTo="opacity-100 scale-100"
    leave="duration-100 ease-in"
    leaveFrom="opacity-100 scale-100"
    leaveTo="opacity-0 scale-95"
  >
    <Popover.Panel
      focus
      className="absolute z-10 top-0 inset-x-0 p-1 transition transform origin-top-right lg:hidden"
    >
      <div
        className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-neutral-50 divide-y-2 divide-gray-50 p-1">
        <div className="pt-1 pb-6 px-2">
          <div className="flex items-center justify-between">
            <div className="flex justify-start items-center" style={{height: 42}}>
              <Logo/>
            </div>

            <Popover.Button>
              <button
                className="bg-neutral-50 rounded-md p-2 inline-flex items-center justify-center text-navy hover:text-red">
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
              </button>
            </Popover.Button>
          </div>

          <MobileMenuElements elements={menu.elements}/>

          <div className={'text-center'}>
            <a
              href={`${env.domain()}/company/contact-sales/`}
              className="mr-4 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border rounded-md text-base font-medium border-cherry bg-cherry text-neutral-50 hover:bg-red-800 hover:border-red-800"
            >
              Contact Sales
            </a>
          </div>
        </div>
      </div>
    </Popover.Panel>
  </Transition>
)
