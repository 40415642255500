import {MenuElement} from "./MenuElement";
import {Popover} from "@headlessui/react"

type MenuProps = {
  menu: Menu
}

export const Menu = ({menu}: MenuProps) => {
  return <Popover.Group className="navigation hidden lg:flex space-x-2 flex-grow justify-between text-navy">
    {menu.elements.map((element, index) => <MenuElement element={element} key={index}/>)}
  </Popover.Group>
}
