import {Bars3Icon} from "@heroicons/react/24/outline";
import {Popover} from "@headlessui/react"

export const MobileMenuOpener = () => (
  <div className="lg:hidden">
    <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-navy hover:text-red">
      <span className="sr-only">Open menu</span>
      <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
    </Popover.Button>
  </div>
)
