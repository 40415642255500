'use client'

import {Disclosure} from '@headlessui/react'
import {useState} from 'react'
import {Transition} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/24/solid'
import {MenuGroup} from './MenuGroup'

type MobileMenuElementsProps = {
  elements: MenuElement[]
}

export const MobileMenuElements = ({elements}: MobileMenuElementsProps) => {
  const [keyOfOpenDisclosure, setKeyOfOpenDisclosure] = useState('')

  const toggleDisclosure = (key: string) => {
    setKeyOfOpenDisclosure(prev => (prev !== key ? key : ''))
  }

  return (
    <div className={'mobile-navigation'}>
      {elements.map((element, i) => element?.subElements && element?.subElements.length > 0
        ? <Disclosure key={i}>
          <div onClick={() => toggleDisclosure(element.title)}>
            <Disclosure.Button className={'button'}>
              <span>{element.title}</span>
              <ChevronDownIcon className={`icon ${keyOfOpenDisclosure === element.title ? 'icon__open' : ''}`}/>
            </Disclosure.Button>
          </div>
          <Transition show={keyOfOpenDisclosure === element.title}>
            <Disclosure.Panel className={'p-2'}>
              {element?.subElements.map(group => (<MenuGroup group={group} key={group.title}/>))}
            </Disclosure.Panel>
          </Transition>
        </Disclosure>
        : <a href={element.url} key={i} className={'py-2 block w-full text-left'}>{element.title}</a>
      )}
    </div>
  )
}
