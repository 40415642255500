type LogoProps = {
    url?: string
    width?: number
    height?: number
}

export const Logo = ({width, height, url = '/'}: LogoProps) => {
    return (
        <a href={url}
           className={'pn-logo'}
           title="PubNub secure global data stream network and easy to use API for realtime Apps"
           data-tracking-id={'header:logo'}
        >
            <img src={'/pubnub_logo.svg'} width={width || 100} height={height || 33} alt={'PubNub'} />
        </a>
    )
}
