import type {
    InkeepAIChatSettings,
    InkeepSearchSettings,
    InkeepWidgetBaseSettings,
    InkeepModalSettings,
} from "@inkeep/widgets";

import {customAnalyticsCallback} from "../../app/layout/search/customAnalyticsCallback"

type InkeepSharedSettings = {
    baseSettings: InkeepWidgetBaseSettings;
    aiChatSettings: InkeepAIChatSettings;
    searchSettings: InkeepSearchSettings;
    modalSettings: InkeepModalSettings;
};

const useInkeepSettings = (): InkeepSharedSettings => {
    const baseSettings: InkeepWidgetBaseSettings = {
        logEventCallback: customAnalyticsCallback,
        apiKey: process.env.NEXT_PUBLIC_INKEEP_INTEGRATION_API_KEY!,
        integrationId: process.env.NEXT_PUBLIC_INKEEP_INTEGRATION_ID!,
        organizationId: process.env.NEXT_PUBLIC_INKEEP_ORGANIZATION_ID!,
        primaryBrandColor: "#D02129",
        organizationDisplayName: "PubNub",
        breadcrumbRules: {
            urlToBreadcrumbMapper: [
                {
                    matchingRule: {
                        ruleType: "PartialUrl",
                        partialUrl: "pubnub.com",
                    },
                    replaceLeading: true,
                    breadcrumbName: "Website",
                },
                {
                    matchingRule: {
                        ruleType: "PartialUrl",
                        partialUrl: "pubnub.com/blog",
                    },
                    replaceLeading: true,
                    breadcrumbName: "Blog",
                },
                {
                    matchingRule: {
                        ruleType: "PartialUrl",
                        partialUrl: "pubnub.com/docs",
                    },
                    replaceLeading: true,
                    breadcrumbName: "Docs",
                },
                {
                    matchingRule: {
                        ruleType: "PartialUrl",
                        partialUrl: "pubnub.com/resources",
                    },
                    replaceLeading: true,
                    breadcrumbName: "Resources",
                },
                {
                    matchingRule: {
                        ruleType: "PartialUrl",
                        partialUrl: "support.pubnub.com/hc/en-us",
                    },
                    replaceLeading: true,
                    breadcrumbName: "Support",
                },
            ],
        },
        stringReplacementRules: [
            {
                matchingRule: {
                    ruleType: 'Substring',
                    string: 'pubnub',
                },
                replaceWith: 'PubNub',
                replaceInTitles: true,
            }
        ],
    };

    const modalSettings: InkeepModalSettings = {
        switchToChatMessage: "Ask AI"
    };

    const searchSettings: InkeepSearchSettings = {
        placeholder: "",
        tabSettings: {
            rootBreadcrumbsToUseAsTabs: ['Docs',"Support", 'Blog', 'Website', 'Resources'],
            isAllTabEnabled: true,
        }
    };

    const aiChatSettings: InkeepAIChatSettings = {
            chatSubjectName: 'PubNub',
            botAvatarSrcUrl: 'https://www.pubnub.com/favicon.ico',
            quickQuestions: [
                'How do I fetch historical messages from a channel?',
                'What is the maximum message size allowed when publishing?',
                'How can I add a device to receive APNs2 notifications on specific channels?',
            ],
        getHelpCallToActions: [
            {
                url: 'https://pubnub.com/company/contact-sales',
                name: 'Support Center',
                icon: {
                    builtIn: 'IoHelpBuoyOutline',
                },
            },
            {
                url: 'https://pubnub.com/company/contact-us',
                name: 'Contact Us',
                icon: {
                    builtIn: 'IoHelpBuoyOutline',
                },
            },
            {
                url: 'https://support.pubnub.com/hc/en-us',
                name: 'Help Center',
                icon: {
                    builtIn: 'IoHelpBuoyOutline',
                },
            },
        ],
    };

    return { baseSettings, aiChatSettings, searchSettings, modalSettings };
};

export default useInkeepSettings;