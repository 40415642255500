import {InkeepCallbackEvent} from "@inkeep/widgets";

export const customAnalyticsCallback = (event: InkeepCallbackEvent): void => {
    switch (event.eventName) {
        case "chat_message_bot_response_received":
            window.analytics.track({
                event: 'chat_message_bot_response_received',
                properties: {
                    question: event.properties.question,
                    number_of_citations_used_in_response: event.properties.numberOfCitationsUsedInResponse,
                    chat_session_id: event.properties.chatSessionId,
                    response_length: event.properties.responseMessage.content.length
                }
            })
            return
        case "search_query_response_received":
            window.analytics.track({
                event: 'search_query_response_received',
                properties: {
                    query: event.properties.query,
                    total_hits: event.properties.totalHits
                }
            })
            return
        default:
            return
    }

};

